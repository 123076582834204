import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/Layout'
import SEOData from '../components/seo'
import {Hero} from '../components/Hero'
import Navigation from '../components/Navigation'

const IndexPage: React.FunctionComponent<any> = ({
  data: {BlueAbstract, OrangeAbstract, LightAbstract},
}) => {
  return (
    <Layout>
      <SEOData title="Home" />
      <Navigation />
      <Hero
        isH1
        bgColor="bg-indigo-200"
        title="Your gateway to NFTs on Cardano"
        description="Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua."
        image={BlueAbstract.childImageSharp.fluid}
        buttons={[
          {
            link: 'Discover',
            href: 'https://kunafa.art',
            colorScheme: 'dark',
            color: 'primary',
          },
          {
            link: 'Create',
            href: 'https://kunafa.art/mint',
            colorScheme: 'light',
            color: 'primary',
          },
        ]}
        scrollColor="dark"
        imageScrollColor="light"
      />
      <Hero
        bgColor="bg-orange-200"
        title="Exclusive Drops from amazing artists"
        description="Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua."
        image={OrangeAbstract.childImageSharp.fluid}
        scrollColor="dark"
        imageScrollColor="dark"
      />
      <Hero
        bgColor="bg-orange-100"
        title="Unique Rewards System with $KNF"
        description="We created $KNF as a Utility Token for longterm users and artists. The more you use our Platforms, the more you will earn $KNF. Eventually you can climb our Rewards Ranks for some sweet sweet surprises."
        image={LightAbstract.childImageSharp.fluid}
        buttons={[{colorScheme: 'dark', href: '#', link: 'Find out more'}]}
        scrollColor="dark"
        imageScrollColor="light"
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    BlueAbstract: file(relativePath: {eq: "blue-abstract.jpg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(sizes: "50vw") {
          ...GatsbyImageSharpFluid
        }
      }
    }
    OrangeAbstract: file(relativePath: {eq: "orange-abstract.jpg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(sizes: "50vw") {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LightAbstract: file(relativePath: {eq: "light-abstract.jpg"}) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(sizes: "50vw") {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
