/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import useMedia from 'use-media'
import {useColorChanger} from '../hooks/useLogoChanger'

const HeroWrapper = styled.section`
  ${tw`relative overflow-hidden flex flex-col`}
  min-height: 100vh;
`

const ImageWrapper = styled.div`
  ${tw`lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 lg:h-full`}
  height: 30vh;
`
const Image = styled.img`
  ${tw`h-full w-full object-cover lg:w-full lg:h-full`}
`

const HeroContent = styled.div`
  ${tw`p-8 md:p-12 lg:p-16 lg:absolute lg:right-0 lg:w-1/2 lg:h-full flex flex-col items-start justify-center`}
  min-height: 70vh;
`
const Title = styled.h1`
  ${tw`text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl leading-none`}
`
const Description = styled.p`
  ${tw`mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl md:mt-5 md:text-xl lg:mx-0`}
`
const ButtonLink = styled.a<any>`
  ${tw`w-full flex items-center justify-center px-4 py-2 text-base border-2 font-medium rounded-md md:py-3 md:text-lg md:px-8`}
`

interface ButtonProps {
  colorScheme: 'light' | 'dark'
  color?: string
  href: string
  link: React.ReactNode
}

interface HeroProps {
  image: any
  title: string
  isH1?: boolean
  description?: string
  bgColor?: string
  buttons?: Array<ButtonProps>
  scrollColor?: 'light' | 'dark'
  imageScrollColor?: 'light' | 'dark'
}

export const Hero: React.FC<HeroProps> = ({
  title,
  image,
  description,
  isH1 = false,
  children = null,
  bgColor = 'bg-white',
  buttons = [],
  scrollColor = 'light',
  imageScrollColor = 'dark',
}) => {
  const wrapperRef = React.useRef()
  const imageRef = React.useRef()
  const isDesktop = useMedia({minWidth: '1024px'})
  useColorChanger({
    color: scrollColor,
    imageColor: imageScrollColor,
    wrapperRef,
    imageRef,
    isDesktop,
  })

  return (
    <HeroWrapper ref={wrapperRef} className={`${bgColor}`}>
      <ImageWrapper ref={imageRef}>
        <Image {...image} />
      </ImageWrapper>
      <HeroContent>
        <Title as={isH1 ? 'h1' : 'h2'}>{title}</Title>
        {description ? <Description>{description}</Description> : null}
        {buttons.length > 0 ? (
          <div className="mt-5 sm:mt-8 flex justify-center lg:justify-start">
            {buttons.map(({href, link, colorScheme, color = 'orange'}) => (
              <div key={href} className="rounded-md shadow mr-4">
                <ButtonLink
                  colorScheme={colorScheme}
                  className={
                    colorScheme === 'light'
                      ? `text-${color as never}-700 border-${
                          color as never
                        }-600 bg-transparent hover:text-${
                          color as never
                        }-700 hover:bg-${color as never}-200`
                      : `border-transparent text-white bg-${
                          color as never
                        }-600 hover:text-white hover:bg-${color as never}-800`
                  }
                  color={color}
                  href={href}
                  // className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-orange-500 hover:text-white hover:bg-orange-700 md:py-3 md:text-lg md:px-8"
                >
                  {link}
                </ButtonLink>
              </div>
            ))}
          </div>
        ) : null}
        {children}
      </HeroContent>
    </HeroWrapper>
  )
}
